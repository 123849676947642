var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[(_vm.profileDialog)?_c('v-dialog',{staticClass:"text-center",attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.profileDialog),callback:function ($$v) {_vm.profileDialog=$$v},expression:"profileDialog"}},[_c('edit-profile',{on:{"closeDialog":function($event){_vm.profileDialog = false}}})],1):_vm._e(),(!_vm.isPcWidth)?_c('show-domains',{staticClass:"show-domain",attrs:{"showDomainsDialog":_vm.showDomainsDialog},model:{value:(_vm.showDomainsDialog),callback:function ($$v) {_vm.showDomainsDialog=$$v},expression:"showDomainsDialog"}}):_vm._e(),(_vm.showBlockedUserModel)?_c('v-dialog',{staticClass:"text-center",attrs:{"transition":"dialog-bottom-transition","persistent":"","max-width":"600"},model:{value:(_vm.showBlockedUserModel),callback:function ($$v) {_vm.showBlockedUserModel=$$v},expression:"showBlockedUserModel"}},[_c('block-user')],1):_vm._e(),_c('v-layout',[_c('v-spacer'),_c('section',{staticClass:"px-3 py-1 block-radios",style:(this.$store.getters.theme === 'dark'
          ? 'background: #00000080'
          : 'background: #e8eff780')},[_c('theme-change'),(_vm.isPcWidth)?_c('v-menu',{attrs:{"dark":_vm.$store.getters.theme === 'dark' ? true : false,"max-height":"80vh","max-width":"150","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"large":"","color":"transparent","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" Domains: "+_vm._s(_vm.userInfo.domains ? _vm.userInfo.domains.length : 0)+" ")])]}}],null,false,1115087019)},[_c('v-list',_vm._l((_vm.userInfo.domains),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',{attrs:{"title":item}},[_vm._v(_vm._s(item))])],1)}),1)],1):_vm._e(),_c('v-menu',{attrs:{"bottom":"","offset-y":true,"dark":_vm.$store.getters.theme === 'dark' ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var onMenu = ref.on;
return [_c('v-btn',_vm._g({staticClass:"pr-0",attrs:{"large":"","color":"transparent","elevation":"0"}},Object.assign({}, onMenu)),[_c('span',{staticClass:"usr-name"},[_vm._v(" "+_vm._s(_vm.userInfo.nickName))]),(_vm.userInfo.img && _vm.userInfo.img.length)?_c('v-list-item-avatar',{staticClass:"ml-2"},[_c('img',{attrs:{"src":_vm.userInfo.img}})]):_c('v-avatar',{staticClass:"main-btn ml-2",attrs:{"color":"primary","size":"40"}},[_vm._v(" "+_vm._s(_vm.userInfo.nickName.slice(1, 3).toUpperCase())+" ")])],1)]}}])},[_c('v-list',{staticClass:"overflow-y-auto blur-menu",staticStyle:{"max-height":"250px","min-width":"200px"}},[(!_vm.isPcWidth)?_c('v-list-item',{staticClass:"nav-menu-btn",on:{"click":function($event){$event.stopPropagation();_vm.showDomainsDialog = !_vm.showDomainsDialog}}},[_c('v-list-item-title',[_vm._v("Show Domains")])],1):_vm._e(),_c('v-list-item',{staticClass:"nav-menu-btn",on:{"click":function($event){$event.stopPropagation();_vm.profileDialog = true}}},[_c('v-list-item-title',[_vm._v("Profile")])],1),_c('v-list-item',{staticClass:"nav-menu-btn",on:{"click":function($event){return _vm.$store.dispatch('SHOW_BLOCKED_USER_POPUP')}}},[_c('v-list-item-title',[_vm._v("Block User")])],1),_c('v-list-item',{staticClass:"nav-menu-btn",on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }