<template>
  <main>
    <div
      class="d-none d-md-block domains pointer"
      @click="openDomainsDialog = true"
    >
      <span> {{ userInfo.nickName }}</span>
      <span v-if="userInfo.domains && userInfo.domains.length"> , </span>
      <span> {{ userInfo.domains.join(", ") }} </span>
    </div>

    <v-dialog v-model="openDomainsDialog" max-width="500px">
      <v-card class="card-bg">
        <v-card-title> {{ userInfo.nickName }} </v-card-title>

        <v-card-text class="">
          <div class="text-subtitle-1">Domains</div>
          <v-divider class="mb-3 mt-1"></v-divider>
          <span
            class="text-subtitle-1 black--text"
            v-for="(domain, index) in userInfo.domains"
            :key="index"
          >
            {{ domain }}
            <span
              v-if="userInfo.domains && index + 1 !== userInfo.domains.length"
            >
              ,&nbsp;
            </span>
          </span>
          <span v-if="!userInfo.domains.length"> No Domains Found </span>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="passcode-data"
      persistent
      v-model="$store.getters.openPasscodeLogin"
    >
      <v-card class="card-bg">
        <v-card-text class="pb-0 px-3">
          <div class="text-subtitle-1 mt-5">
            Session time expired! <br />
            <small> Use your passcode to refresh the session duration. </small>
          </div>
          <v-text-field
            :append-icon="showPasscode ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passcodeRules"
            :type="showPasscode ? 'text' : 'password'"
            v-model="passcode"
            id="passcode"
            label="Enter Passcode"
            class="input-group--focused"
            @click:append="showPasscode = !showPasscode"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="discardPasscode()"> logout </v-btn>
          <v-btn color="primary" text @click="loginWithPasscode()">
            Refresh
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      content-class="passcode-data"
      persistent
      v-model="this.$store.getters.showPassCodeDialog"
    >
      <v-card class="card-bg">
        <v-card-text
          v-if="acceptPasscode === false"
          class="text-subtitle-1 mt-5 px-3"
        >
          <div>
            Do you want to set a Passcode? <br />
            <small>
              Your Passcode will only be stored in your local machine and can be
              used to login without Seed words. <br />
              <strong>It works only on this device</strong>
            </small>
          </div>
        </v-card-text>
        <v-card-text v-else class="pb-0 px-3">
          <div class="text-subtitle-1 mt-5">
            Note: Passcode will not be saved on the server and each devices will
            have separate passcode.
          </div>

          <v-text-field
            :append-icon="showPasscode ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passcodeRules"
            :type="showPasscode ? 'text' : 'password'"
            v-model="passcode"
            id="passcode"
            label="Enter Passcode"
            class="input-group--focused"
            @click:append="showPasscode = !showPasscode"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closePasscodeDialog()"> Discard </v-btn>
          <v-btn
            color="primary"
            text
            v-if="acceptPasscode === false"
            @click="acceptPasscode = true"
          >
            Yes
          </v-btn>
          <v-btn color="primary" text v-else @click="setPasscode()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main>
</template>

<script>
export default {
  props: ["showDomainsDialog"],
  watch: {
    showDomainsDialog() {
      this.openDomainsDialog = true;
    },
  },
  data: () => ({
    openDomainsDialog: false,
    showPasscode: false,
    acceptPasscode: false,
    passcodeRules: [
      (v) => (v && v.length > 3) || "Minimum 4 characters required",
    ],
    passcode: null,
    rePasscode: null,
  }),
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    // ShowPassCodeDialog() {
    //   if (this.$store.getters.hidePassCodeDialog)
    //   return !this.$store.getters.hidePassCodeDialog;
    //   return localStorage.getItem("disable_passcode") ? false : true;
    // },
  },
  methods: {
    closePasscodeDialog() {
      this.$store.commit("SHOW_SET_PASSCODE_DIALOG", false);
      localStorage.setItem("disable_passcode", true);
    },
    setPasscode() {
      this.$store
        .dispatch("DATA_ENCRYPT", {
          message: JSON.stringify({
            nickName: this.$store.getters.userInfo.nickName,
            mnemonic: this.$store.getters.userInfo.mnemonic,
          }),
          secret: this.passcode,
        })
        .then((data) => {
          localStorage.setItem(`p_c`, data);
          this.$store.commit("SHOW_SET_PASSCODE_DIALOG", false);

          this.$toast.success("Passcode saved successfully");
          localStorage.setItem("disable_passcode", true);
        });
    },
    loginWithPasscode() {
      if (!this.passcode || this.passcode.length < 4)
        this.$toast("Please enter a valid Passcode");

      this.$store
        .dispatch("DATA_DECRYPT", {
          cipherText: localStorage.getItem("p_c"),
          secret: this.passcode,
        })
        .then((data) => {
          if (!data || data.length < 15)
            return this.$toast.error("Incorrect Passcode");

          this.$store.commit("SHOW_PASSCODE_DIALOG", false);
          this.$store
            .dispatch("AUTH_LOGIN", JSON.parse(data))
            .then(() => {
              this.$store.dispatch("GLOBAL_INIT", {
                skipAuthInit: true,
                addMailInit: true,
              });
              this.$router.push("/");
            })
            .catch((message) => {
              console.error(message);
              this.$toast.error(message);
            });
        })
        .catch(() => {
          this.$toast.error("Incorrect Passcode");
        });
    },
    discardPasscode() {
      localStorage.removeItem("p_c");
      this.$store.dispatch("SESSION_EXPIRED");
    },
  },
};
</script>

<style>
.passcode-data {
  width: 400px !important;
  max-width: 90%;
}
.passcode-data.v-dialog {
  position: absolute;
  right: 0;
  top: 40px;
  border: 1px solid;
}
.card-bg {
  box-shadow: 0px 10px 50px 0 #1f268733;
  border: 1px solid #ffffff26;
  backdrop-filter: blur(10px);
}

.domains {
  overflow: hidden;
  width: 600px;
  white-space: nowrap;
  max-width: 50vw;
  cursor: pointer;
  text-align: right;
  text-overflow: ellipsis;
}
</style>
