<template>
  <main
    class="main-wrapper"
    :class="$store.getters.theme === 'dark' ? 'dark-loading' : 'light-loading'"
  >
    <div
      class="loading"
      :class="
        $store.getters.theme === 'dark' ? 'loading-icon-d' : 'loading-icon'
      "
    >
      <div class="loading-square">
        <div class="square first"></div>
        <div class="square second"></div>
        <div class="square third"></div>
        <div class="square fourth"></div>
      </div>
      <div class="status-text">Loading</div>
    </div>
  </main>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.light-loading {
  background: #0ae;
}

.dark-loading {
  background: #04081c;
  // background: #181818;
}

.main-wrapper {
  text-align: center;
  line-height: 100vh;
}

$icon-width: 40px;

.loading-icon-d {
  $icon-color: #873f99;

  position: relative;
  display: inline-block;
  line-height: $icon-width / 2;

  .loading-square {
    position: relative;
    width: $icon-width;
    height: $icon-width;
    overflow: hidden;
    display: inline-block !important;
    transform-origin: bottom left;
    animation: bigSqrShrink 1s linear infinite;

    .square {
      position: absolute;
      width: $icon-width / 2;
      height: $icon-width / 2;
      background: $icon-color;
    }

    .first {
      left: 0px;
      border-radius: 0px 0px 0px 4px;
      top: $icon-width / 2;
    }

    .second {
      left: $icon-width / 2;
      top: $icon-width / 2;
      border-radius: 0px 0px 4px 0px;
      animation: drop2 1s linear infinite;
    }

    .third {
      left: 0px;
      top: 0px;
      border-radius: 4px 0px 0px 0px;
      animation: drop3 1s linear infinite;
    }

    .fourth {
      left: $icon-width / 2;
      top: 0px;
      border-radius: 0px 4px 0px 0px;
      animation: drop4 1s linear infinite;
    }
  }

  .status-text {
    line-height: $icon-width / 2;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: $icon-width / 2;
    color: $icon-color;
    display: block;
    margin: $icon-width / 4 auto;
    padding: 3px;
  }
}

.loading-icon {
  $icon-color: #fff;

  position: relative;
  display: inline-block;
  line-height: $icon-width / 2;

  .loading-square {
    position: relative;
    width: $icon-width;
    height: $icon-width;
    overflow: hidden;
    display: inline-block !important;
    transform-origin: bottom left;
    animation: bigSqrShrink 1s linear infinite;

    .square {
      position: absolute;
      width: $icon-width / 2;
      height: $icon-width / 2;
      background: $icon-color;
    }

    .first {
      left: 0px;
      border-radius: 0px 0px 0px 4px;
      top: $icon-width / 2;
    }

    .second {
      left: $icon-width / 2;
      top: $icon-width / 2;
      border-radius: 0px 0px 4px 0px;
      animation: drop2 1s linear infinite;
    }

    .third {
      left: 0px;
      top: 0px;
      border-radius: 4px 0px 0px 0px;
      animation: drop3 1s linear infinite;
    }

    .fourth {
      left: $icon-width / 2;
      top: 0px;
      border-radius: 0px 4px 0px 0px;
      animation: drop4 1s linear infinite;
    }
  }

  .status-text {
    line-height: $icon-width / 2;
    font-family: "Open Sans", Arial, sans-serif;
    font-size: $icon-width / 2;
    color: $icon-color;
    display: block;
    margin: $icon-width / 4 auto;
    padding: 3px;
  }
}

@keyframes bigSqrShrink {
  0% {
    transform: scale(1);
  }
  90% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}

@keyframes drop2 {
  0% {
    transform: translateY(-50px);
  }
  25% {
    transform: translate(0);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes drop3 {
  0% {
    transform: translateY(-50px);
  }
  50% {
    transform: translate(0);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes drop4 {
  0% {
    transform: translateY(-50px);
  }
  75% {
    transform: translate(0);
  }
  100% {
    transform: translate(0);
  }
}
</style>
