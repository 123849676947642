<template>
  <main></main>
</template>

<script>
import { debounce } from "debounce";
import { io } from "socket.io-client";
// import { EventBus } from "@/plugins/event-bus";

const socket = io(process.env.VUE_APP_NOTIFICATION);

export default {
  data: () => ({
    isSocketOpenForDomains: false,
  }),
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  created() {
    const vueObj = this;
    if (!this.isSocketOpenForDomains) {
      this.isSocketOpenForDomains = true;
      for (
        let index = 0;
        index < vueObj.$store.getters.userInfo.domains.length;
        index++
      ) {
        const ele = vueObj.$store.getters.userInfo.domains[index];
        socket.on(ele, (data) => {
          // vueObj.$store.dispatch("NEW_INBOX", data);
          vueObj.callInbox(data);
        });
      }
    }
    socket.on(this.userInfo.nickName, (data) => {
      this.callInbox(data);
    });
  },
  methods: {
    callInbox: debounce(async function (data) {
      this.$store.dispatch("NEW_INBOX", data);
      // EventBus.$emit("inboxUpdated", data);
    }, 1000),
  },
};
</script>
