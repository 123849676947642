var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('div',{on:{"mouseover":_vm.mouseover,"mouseleave":_vm.mouseleave}},[(_vm.isPcWidth)?_c('v-card',{staticClass:"expand-on-hover-card",attrs:{"dark":"","min-height":"100%","width":"56"}},_vm._l((_vm.innerNav),function(item){return _c('v-list-item-group',{key:item.title},[_c('v-list',{attrs:{"dense":""}},[(!item.option)?_c('v-list-item',{attrs:{"link":"","title":item.tooltip},on:{"click":function($event){return _vm.openHashtagLink(item.link)}}},[_c('v-list-item-action',[(!item.src)?_c('v-icon',{style:(item.icon == 'BACKSLASH' ? 'transform: rotate(127deg)' : '')},[_vm._v(" "+_vm._s(item.icon)+" ")]):_c('v-img',{attrs:{"src":item.src,"max-height":"24","max-width":"24"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" "),_c('br'),_c('small',[_vm._v(_vm._s(item.dis.toUpperCase()))])])],1)],1):_c('v-list-group',{attrs:{"title":item.tooltip,"color":"white","value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-action',[(!item.src)?_c('v-icon',{style:(item.icon == 'BACKSLASH'
                      ? 'transform: rotate(127deg)'
                      : '')},[_vm._v(" "+_vm._s(item.icon)+" ")]):_c('v-img',{attrs:{"src":item.src,"max-height":"24","max-width":"24"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.title)+" "),_c('br'),_c('small',[_vm._v(_vm._s(item.dis.toUpperCase()))])])],1)]},proxy:true}],null,true)},_vm._l((item.option),function(ref,i){
                      var title = ref[0];
                      var link = ref[1];
return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.openHashtagLink(link)}}},[_c('v-list-item-action',[(!item.src)?_c('v-icon',{style:(item.icon == 'BACKSLASH'
                      ? 'transform: rotate(127deg)'
                      : ''),attrs:{"small":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]):_c('v-img',{attrs:{"src":item.src,"max-height":"16","max-width":"16"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(title))])])],1)],1)}),1)],1)],1)}),1):_vm._e()],1),(!_vm.isMiniNav)?_c('v-list',{staticClass:"grow list-m-nav"},[_c('v-list-item',{staticClass:"text-center"},[_c('v-btn',{staticClass:"main-btn mb-8 mt-3",attrs:{"large":"","rounded":"","height":"50","width":"100%","max-width":"200","light":""},on:{"click":function($event){return _vm.$emit('openCompose')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-pencil ")]),_vm._v(" Compose ")],1)],1),_c('v-list-item-group',{attrs:{"mandatory":"","tile":""},model:{value:(_vm.selectedMainNav),callback:function ($$v) {_vm.selectedMainNav=$$v},expression:"selectedMainNav"}},[_vm._l((_vm.mainNavItems),function(item){return _c('v-list-item',{key:item.text,attrs:{"dense":"","color":_vm.getColor(item),"link":""},on:{"click":function($event){return _vm.$router.push(("/mail/" + (item.text)))}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-title',{staticClass:"text-capitalize",domProps:{"textContent":_vm._s(item.text)}})],1)}),_c('dash-folder')],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }