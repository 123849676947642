<template>
  <v-card color="#1f1e1e80" class="mail-view-box">
    <div class="px-1 text-end">
      <v-icon class="white--text" @click="$emit(`closeDialog`)">
        mdi-close
      </v-icon>
    </div>

    <v-card-text>
      <v-container>
        <v-row justify="center">
          <v-col cols="auto">
            <div class="text-h5 font-weight-medium white--text">
              Edit Profile
            </div>
          </v-col>
        </v-row>
        <v-row class="pt-5 ma-0" justify="space-around" align="center">
          <v-col cols="12" sm="6 ">
            <p class="white--text mb-0">First Name:</p>
            <v-text-field
              :dark="$store.getters.theme === 'dark' ? true : false"
              placeholder="First Name *"
              hide-details
              flat
              v-model.trim="firstName"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <p class="white--text mb-0">Last Name:</p>
            <v-text-field
              placeholder="Last Name *"
              hide-details
              :dark="$store.getters.theme === 'dark' ? true : false"
              flat
              v-model.trim="lastName"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <p class="white--text mb-0">Email:</p>
            <v-text-field
              placeholder="Email"
              :dark="$store.getters.theme === 'dark' ? true : false"
              solo
              disabled
              hide-details
              v-model.trim="userInfo.emailId"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <p class="white--text mb-0">Hashtag name:</p>
            <v-text-field
              placeholder="Hashtag Name"
              :dark="$store.getters.theme === 'dark' ? true : false"
              solo
              hide-details
              disabled
              v-model.trim="userInfo.nickName"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <v-layout>
              <!-- <v-btn
                class="teal white--text ml-1 button"
                @click="OpenBlockUserDialog()"
              >
                Block User
              </v-btn>
              <v-spacer></v-spacer> -->
              <v-btn
                :loading="loading"
                :disabled="!valid"
                width="150"
                class="teal orange white--text ml-1 button"
                @click="saveData()"
              >
                Save
              </v-btn>
            </v-layout>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: false,
      loading: false,
      firstName: "",
      lastName: "",
      userInfo: {},
    };
  },

  watch: {
    firstName: function (newData) {
      if (newData && newData !== this.userInfo.firstName) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    lastName: function (newData) {
      if (newData && newData !== this.userInfo.lastName) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
  },
  computed: {
    getData() {
      return this.$store.getters.userInfo;
    },
  },
  methods: {
    OpenBlockUserDialog() {
      this.$emit(`closeDialog`);
      this.$store.dispatch("SHOW_BLOCKED_USER_POPUP");
    },
    saveData() {
      this.userInfo.firstName = this.firstName;
      this.userInfo.lastName = this.lastName;

      this.loading = true;
      this.$store
        .dispatch("EDIT_USER", this.userInfo)
        .then(() => {
          setTimeout(() => {
            this.valid = false;
            this.loading = false;
            this.$toast.success("Updated Successfully");
            this.$emit(`closeDialog`);
          }, 100);
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error(error);
        });
    },
    setPasscode() {
      localStorage.removeItem("disable_passcode");
      this.$store.commit("SHOW_SET_PASSCODE_DIALOG", true);
    },
  },
  created() {
    this.userInfo = this.getData;
    this.firstName = this.userInfo.firstName;
    this.lastName = this.userInfo.lastName;
  },
};
</script>

<style scoped>
.dialog {
  color: white;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  color: white !important;
  background: grey !important;
}
.button {
  cursor: pointer;
}
</style>
