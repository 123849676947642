<template>
  <section>
    <v-subheader class="mt-5">
      <v-row justify="space-between">
        <v-col cols="auto"> Folders</v-col>
        <v-col cols="auto">
          <v-icon @click="createFolder()"> mdi-plus-circle-outline </v-icon>
        </v-col>
      </v-row>
    </v-subheader>
    <v-divider></v-divider>
    <!-- <v-list dense style="overflow-y: auto;">
      <v-list-item-group v-model="foldersListNav"> -->
    <v-list>
      <v-list-item
        @click="$router.push(`/mail/folder/${i}`)"
        v-for="(item, i) in foldersList"
        :key="i"
      >
        <v-list-item-icon>
          <v-icon> mdi-folder</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title v-text="item"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-menu
            offset-y
            left
            origin="center center"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="menu-icon" v-bind="attrs" v-on="on">
                mdi-dots-vertical
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="renameFolder(i)">
                <v-list-item-title>Rename</v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteFolder(i)">
                <v-list-item-title>Delete </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
    <!-- </v-list-item-group>
    </v-list> -->

    <v-dialog
      width="500"
      max-width="90%"
      :close.sync="createFolderDialog"
      v-model="createFolderDialog"
    >
      <edit-folder
        :createFolderDialog="createFolderDialog"
        @closeDialog="createFolderDialog = false"
        :folderTagName="folderTagName"
      >
      </edit-folder>
    </v-dialog>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import EditFolder from "./EditFolder";

export default {
  components: {
    EditFolder,
  },
  props: ["routerName"],
  data: () => ({
    foldersListNav: 0,
    folderTagName: "",
    createFolderDialog: false,
  }),
  computed: {
    ...mapGetters(["foldersList"]),
  },
  watch: {
    routerName(name) {
      if (name != "user folders") return (this.foldersListNav = -1);

      const index = Object.keys(this.foldersList).findIndex(
        (data) => data == this.$route.params.id
      );
      this.foldersListNav = index;
    },
  },

  methods: {
    getTotalCount(tagName) {
      if (this.$store.getters.userFolderDetails[tagName])
        return this.$store.getters.userFolderDetails[tagName].totalCount;
      return 0;
    },
    createFolder() {
      this.folderTagName = "";
      this.createFolderDialog = true;
    },
    renameFolder(tagName) {
      this.folderTagName = tagName;
      this.createFolderDialog = true;
    },
    deleteFolder(tagName) {
      if (this.getTotalCount(tagName))
        return this.$toast.error("Folder is not empty");

      this.deleteFolderLoading = true;
      this.$store
        .dispatch("DELETE_FOLDER", tagName)
        .then(() => {
          this.deleteFolderLoading = false;
          this.$router.push("/");
          this.$toast.error("Folder Deleted Successfully");
        })
        .catch((error) => {
          this.$toast.error(error);
          this.deleteFolderLoading = false;
        });
    },
  },
  created() {
    // // Rename Folder
    // this.$store
    //   .dispatch("RENAME_FOLDER", {
    //     folderName: "Rename",
    //     folderTag: "61daaf2f7e3b4a43c84b82eb",
    //   })
    //   .then(() => {
    //     this.createFolderLoading = false;
    //     this.createFolderDialog = false;
    //     this.$store.dispatch("ALERT_SUCCESS", "Folder Renamed Successfully");
    //   })
    //   .catch((error) => {
    //     this.$store.dispatch("ALERT_ERROR", error);
    //     this.createFolderLoading = false;
    //   });
    // // Delete Folder
    // this.$store
    //   .dispatch("DELETE_FOLDER", "61dad7e135f55d2c131be3ba")
    //   .then(() => {
    //     this.createFolderLoading = false;
    //     this.createFolderDialog = false;
    //     this.$store.dispatch("ALERT_SUCCESS", "Folder Deleted Successfully");
    //   })
    //   .catch((error) => {
    //     this.$store.dispatch("ALERT_ERROR", error);
    //     this.createFolderLoading = false;
    //   });
  },
};
</script>

<style scoped>
::v-deep .menu-icon.v-icon.v-icon {
  position: absolute;
  right: 10px;
}
::v-deep .menu-icon.v-icon.v-icon::after {
  /* display: none !important; */
  background-color: transparent !important;
}
</style>
