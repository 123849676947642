<template>
  <v-container
    :class="fullscreen ? 'lg-compose-wrapper' : 'sm-compose-wrapper'"
    class="pa-0"
    style="border-radius: 10px"
    fluid
  >
    <v-card
      height="100%"
      :dark="$store.getters.theme === 'dark' ? true : false"
      :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'"
    >
      <new-compose
        :type="'newMail'"
        :defaultTitle="'New Mail'"
        :fullscreen="fullscreen"
        @fullscreen="fullscreen = !fullscreen"
        @discard="discord()"
      ></new-compose>
    </v-card>
  </v-container>
</template>

<script>
import NewCompose from "./newCompose.vue";

export default {
  components: { NewCompose },
  data: () => ({
    fullscreen: false,
  }),
  methods: {
    discord() {
      this.fullscreen = false;
      this.$emit("discard");
    },
  },
};
</script>

<style lang="scss" scoped>
.sm-compose-wrapper {
  background: white;
  position: fixed;
  z-index: 5;
  width: 600px;
  max-width: calc(100% - 20px);
  bottom: 50px;
  right: 10px;
  height: 560px;
}

.lg-compose-wrapper {
  top: 0;
  left: 0;
  height: calc(100vh - 52px);
  width: 100vw;
  position: fixed;
  z-index: 10;
}
// .compose-form {
//   border: 1px solid #ccc;
//   border-radius: 2px;
//   &--input {
//     border-bottom: 1px solid #ccc !important;
//   }
//   &--footer {
//     background: #f3f3f3;
//   }
//   .input-error {
//     font-size: 0.75rem;
//     color: red !important;
//     border-left: 4px solid red;
//     border-radius: 0;
//   }
// }
</style>
