<template>
  <initial-loading v-if="$store.getters.initMailStatus == 'loading'">
  </initial-loading>
  <v-app
    v-else
    :class="$store.getters.theme === 'dark' ? 'dark-theme' : 'light-theme'"
  >
    <v-app-bar
      app
      clipped-left
      :height="isPcWidth ? '90' : '60'"
      width="100%"
      elevation="0"
      :dark="$store.getters.theme === 'dark' ? true : false"
      color="transparent"
    >
      <v-app-bar-nav-icon
        v-if="!isPcWidth"
        @click.stop="navDrawer = !navDrawer"
      ></v-app-bar-nav-icon>
      <logo></logo>
      <v-spacer></v-spacer>
      <app-bar-options :isPcWidth="isPcWidth"></app-bar-options>
    </v-app-bar>

    <!-- :class="$store.getters.theme === 'dark' ? 'glb-border-top' : ''" -->
    <v-navigation-drawer
      app
      v-model="navDrawer"
      :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'"
      :dark="$store.getters.theme === 'dark' ? true : false"
      clipped
      width="350"
    >
      <navbar @openCompose="showCompose = true" :isPcWidth="isPcWidth"></navbar>
    </v-navigation-drawer>

    <v-main :style="authBg" class="auth-bg">
      <compose v-if="showCompose" @discard="showCompose = false"></compose>

      <div class="main-content">
        <router-view :key="$route.fullPath"></router-view>
      </div>

      <socket></socket>
      <div class="bottom-panel"></div>
    </v-main>
  </v-app>
</template>

<script>
import Logo from "../global-components/Logo.vue";
import AppBarOptions from "./dashboard-components/AppBarOptions.vue";
import Navbar from "./dashboard-components/navbar.vue";
import Socket from "./dashboard-components/socket.vue";

import Compose from "./compose/compose.vue";
import InitialLoading from "./InitialLoading.vue";

export default {
  components: { Logo, AppBarOptions, Navbar, Socket, Compose, InitialLoading },
  data: () => ({
    navDrawer: true,
    isPcWidth: true,
    showCompose: false,
  }),
  created() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        this.isPcWidth = false;
        this.navDrawer = false;
        break;
      case "sm":
        this.isPcWidth = false;
        this.navDrawer = false;
        break;
      case "md":
        this.isPcWidth = false;
        this.navDrawer = false;
        break;
      case "lg":
        this.isPcWidth = true;
        this.navDrawer = true;
        break;
      case "xl":
        this.isPcWidth = true;
        this.navDrawer = true;
        break;
      default:
        this.isPcWidth = true;
        this.navDrawer = true;
        break;
    }

    if (this.$store.getters.fromAuthPage) {
      this.$store.commit("SHOWING_AUTH_PAGE_STATUS", true);

      // this.$store.dispatch("GET_PRIVATEKEY");
      this.$store.dispatch("MAIL_INIT", { emailId: this.userInfo.nickName });
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    authBg: function () {
      if (this.$store.getters.theme === "dark")
        return {
          backgroundImage: `url(${require("@/assets/hashtag-bg.jpeg")})`,
        };
      return {
        backgroundImage: `url(${require("@/assets/main-bg-w-mini.jpg")})`,
      };
    },
  },
};
</script>

<style scoped>
.auth-bg {
  height: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: right top;
}

.bottom-panel {
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: right;
  z-index: 201;
  padding: 0 0.4rem;
  pointer-events: none;
}

.main-content {
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 100%;
}

.app-border-bottom {
  border-bottom: 1px solid #d2d2d2;
}

.dark-theme {
  background: #04081c;
}

.light-theme {
  background: #e7eef7;
}

::v-deep .v-toolbar__content {
  height: auto !important;
}
</style>
