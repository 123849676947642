<template>
  <v-container fluid class="pa-0">
    <v-dialog
      transition="dialog-bottom-transition"
      v-model="profileDialog"
      v-if="profileDialog"
      max-width="600"
      class="text-center"
    >
      <edit-profile v-on:closeDialog="profileDialog = false"></edit-profile>
    </v-dialog>

    <show-domains
      v-model="showDomainsDialog"
      v-if="!isPcWidth"
      class="show-domain"
      :showDomainsDialog="showDomainsDialog"
    ></show-domains>

    <v-dialog
      transition="dialog-bottom-transition"
      v-model="showBlockedUserModel"
      v-if="showBlockedUserModel"
      persistent
      max-width="600"
      class="text-center"
    >
      <block-user></block-user>
    </v-dialog>

    <v-layout>
      <v-spacer></v-spacer>
      <section
        class="px-3 py-1 block-radios"
        :style="
          this.$store.getters.theme === 'dark'
            ? 'background: #00000080'
            : 'background: #e8eff780'
        "
      >
        <theme-change></theme-change>
        <v-menu
          v-if="isPcWidth"
          :dark="$store.getters.theme === 'dark' ? true : false"
          max-height="80vh"
          max-width="150"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              color="transparent"
              v-bind="attrs"
              v-on="on"
              elevation="0"
            >
              Domains: {{ userInfo.domains ? userInfo.domains.length : 0 }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in userInfo.domains" :key="index">
              <v-list-item-title :title="item">{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu
          bottom
          :offset-y="true"
          :dark="$store.getters.theme === 'dark' ? true : false"
        >
          <template v-slot:activator="{ on: onMenu }">
            <v-btn
              v-on="{ ...onMenu }"
              large
              color="transparent"
              elevation="0"
              class="pr-0"
            >
              <span class="usr-name"> {{ userInfo.nickName }}</span>

              <v-list-item-avatar
                v-if="userInfo.img && userInfo.img.length"
                class="ml-2"
              >
                <img :src="userInfo.img" />
              </v-list-item-avatar>
              <v-avatar v-else color="primary" size="40" class="main-btn ml-2">
                {{ userInfo.nickName.slice(1, 3).toUpperCase() }}
              </v-avatar>
            </v-btn>
          </template>
          <v-list
            style="max-height: 250px; min-width: 200px"
            class="overflow-y-auto blur-menu"
          >
            <v-list-item
              class="nav-menu-btn"
              v-if="!isPcWidth"
              @click.stop="showDomainsDialog = !showDomainsDialog"
            >
              <v-list-item-title>Show Domains</v-list-item-title>
            </v-list-item>

            <v-list-item
              class="nav-menu-btn"
              @click.stop="profileDialog = true"
            >
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>

            <v-list-item
              class="nav-menu-btn"
              @click="$store.dispatch('SHOW_BLOCKED_USER_POPUP')"
            >
              <v-list-item-title>Block User</v-list-item-title>
            </v-list-item>
            <v-list-item class="nav-menu-btn" @click="logout()">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </section>
    </v-layout>
  </v-container>
</template>

<script>
import ThemeChange from "../../global-components/ThemeChange.vue";
import EditProfile from "./EditProfile.vue";
import BlockUser from "./BlockUser.vue";
import ShowDomains from "./ShowDomains.vue";

export default {
  components: { ThemeChange, EditProfile, BlockUser, ShowDomains },
  props: ["isPcWidth"],
  data: () => ({
    showDomainsDialog: false,
    profileDialog: false,
  }),
  computed: {
    showBlockedUserModel: {
      get: function () {
        return this.$store.getters.showBlockedUserModel;
      },
      set: function () {
        return this.$store.dispatch("HIDE_BLOCKED_USER_POPUP");
      },
    },
    userInfo: function () {
      return this.$store.getters.userInfo;
    },
    // appBarOptStyle: function () {
    //   if (this.$store.getters.theme === "dark") return "background: #000000cc";
    //   return "background: #e8eff7cc";
    // },
  },
  methods: {
    logout() {
      this.$store.dispatch("AUTH_LOGOUT", { removeCookies: false }).then(() => {
        this.$router.push("/auth/login");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 768px) {
  .show-domain {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .usr-name {
    position: relative;
    max-width: 35vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    vertical-align: sub;
  }
}

@media only screen and (max-width: 500px) {
  .usr-name {
    max-width: 20vw;
  }
}

@media only screen and (max-width: 400px) {
  .usr-name {
    display: none;
  }
}
</style>
