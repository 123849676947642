<template>
  <v-row class="fill-height" no-gutters>
    <div v-on:mouseover="mouseover" @mouseleave="mouseleave">
      <v-card
        dark
        min-height="100%"
        v-if="isPcWidth"
        width="56"
        class="expand-on-hover-card"
      >
        <v-list-item-group v-for="item in innerNav" :key="item.title">
          <v-list dense>
            <!-- <v-list dense @click="window.open(item.link, '_blank')"> -->
            <v-list-item
              link
              @click="openHashtagLink(item.link)"
              v-if="!item.option"
              :title="item.tooltip"
            >
              <v-list-item-action>
                <v-icon
                  v-if="!item.src"
                  :style="
                    item.icon == 'BACKSLASH' ? 'transform: rotate(127deg)' : ''
                  "
                >
                  {{ item.icon }}
                </v-icon>
                <v-img :src="item.src" max-height="24" max-width="24" v-else>
                </v-img>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }} <br />
                  <small>{{ item.dis.toUpperCase() }}</small>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              :title="item.tooltip"
              v-else
              color="white"
              :value="false"
            >
              <template v-slot:activator>
                <v-list-item-action>
                  <v-icon
                    v-if="!item.src"
                    :style="
                      item.icon == 'BACKSLASH'
                        ? 'transform: rotate(127deg)'
                        : ''
                    "
                  >
                    {{ item.icon }}
                  </v-icon>
                  <v-img :src="item.src" max-height="24" max-width="24" v-else>
                  </v-img>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.title }} <br />
                    <small>{{ item.dis.toUpperCase() }}</small>
                  </v-list-item-title>
                </v-list-item-content>
              </template>

              <!-- <v-list-item v-for="([title], i) in item.option" :key="i" link> -->
              <v-list-item
                v-for="([title, link], i) in item.option"
                :key="i"
                link
                @click="openHashtagLink(link)"
              >
                <v-list-item-action>
                  <v-icon
                    v-if="!item.src"
                    small
                    :style="
                      item.icon == 'BACKSLASH'
                        ? 'transform: rotate(127deg)'
                        : ''
                    "
                  >
                    {{ item.icon }}
                  </v-icon>
                  <v-img :src="item.src" max-height="16" max-width="16" v-else>
                  </v-img>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    <span class="pl-2">{{ title }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-list-item-group>
      </v-card>
    </div>

    <v-list v-if="!isMiniNav" class="grow list-m-nav">
      <v-list-item class="text-center">
        <v-btn
          large
          class="main-btn mb-8 mt-3"
          rounded
          height="50"
          width="100%"
          max-width="200"
          light
          @click="$emit('openCompose')"
        >
          <v-icon left> mdi-pencil </v-icon>
          Compose
        </v-btn>
      </v-list-item>
      <v-list-item-group mandatory tile v-model="selectedMainNav">
        <v-list-item
          dense
          v-for="item in mainNavItems"
          :key="item.text"
          @click="$router.push(`/mail/${item.text}`)"
          :color="getColor(item)"
          link
        >
          <!-- <v-list-item-title v-text="link"></v-list-item-title> -->
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title
            class="text-capitalize"
            v-text="item.text"
          ></v-list-item-title>
          <!-- <v-list-item-content>
          </v-list-item-content> -->
        </v-list-item>
        <dash-folder></dash-folder>
      </v-list-item-group>
    </v-list>
  </v-row>
</template>

<script>
import DashFolder from "./DashFolder";
import { mapGetters } from "vuex";

export default {
  components: { DashFolder },
  props: ["isPcWidth"],
  data: () => ({
    innerNav: [
      {
        title: "COMMAND",
        tooltip:
          "Command and conquer Hashtag space by finding and purchasing popular hashtag domains.  Hashtag domains can be pointed to any where on the web, dark web, or IPFS web 3.0 location you would like or can be held and resold as our platform gains in popularity.",
        dis: "Prospect",
        src: require("@/assets/icons/prospect-white.svg"),
        link: "https://hashtag.space/domainsresult/",
      },
      {
        title: "CONTROL",
        tooltip:
          "Take control of your decentralized #domains.  Here you can set your target URL, manage your auto-renewal, gift domains, and even list your domain for resell.  You can also purchase keywords for each domain to get your domain listed when users search our “Option” decentralized search engine.",
        dis: "Domains",
        icon: "mdi-pound",
        link: "https://hashtag.space/domainsdashboard/",
      },
      {
        title: "SPACEBAR",
        tooltip:
          "Hang out with friends, surf social media, and even list products for sale, in #Space’s very own Social Media platform.  All the features you know and love without “Big Tech” overreaching or shutting you down.  If you hold MHC we hold your content for life!   If you drop MHC we drop your content.",
        dis: "Social",
        icon: "mdi-share-variant",
        link: "https://spacebar.hashtag.space/",
      },
      {
        title: "FUNCTION",
        tooltip:
          "Learn How-to use various hashtag.space and MHC tools and features.  From purchasing MCH, to staking, and even learning about companies and products that support and feature MHC.",
        dis: "MHC",
        icon: "mdi-pound",
        src: require("@/assets/icons/mhc-white.svg"),
        option: [
          ["MHC / Help", "https://mhc.zone/"],
          ["MHC / Watch", "https://mhc.watch/"],
          [
            "MHC / Connect",
            "https://apps.apple.com/us/app/mhconnect/id1563606290#?platform=iphone",
          ],
          ["MHC / Readme", "https://metahash.readme.io/docs"],
          ["MHC / Astake", "https://astake.io/"],
        ],
      },
      {
        title: "CAPS LOCK",
        tooltip:
          "Our premier decentralized, block chain based, email system allows you to securely send emails from one hashtag to another.  Every user gets a free email account for their hashtag.space profile name that sends and receives emails on the block chain.   Additionally every hashtag domain you purchase also gets an email account.",
        dis: "D-Mail",
        src: require("@/assets/icons/dmail-white.svg"),
        link: "https://mail1.hashtag.space/",
      },
      {
        title: "BACKSLASH",
        tooltip:
          "An internal listing of our products and services for sale in our platform.  Expansion will include services and products across web 2.0, web 3.0, and the dark web available for purchase in our decentralized platform.  You can submit your own product or service for consideration.",
        dis: "Market Space",
        icon: "mdi-slash-forward",
        link: "https://hashtag.space/marketspace",
      },
      {
        title: "OPTION",
        tooltip:
          "Our search is unique in that it’s decentralized and controlled by user input.  Each Hashtag Domain will choose and upgrade keywords.  Various tasks can be complete to earn points to help rank higher based on relevancy and participation.",
        dis: "Search",
        icon: "mdi-magnify",
        link: "https://hashtag.space/search",
      },
      {
        title: "RETURN",
        tooltip:
          "Put the power of #’s to work for you.  We offer 25% commissions for life on all referrals you bring to the platform.  Anything they buy, you earn - Instantly! We are also developing the words first commission based crypto currencies that can be used for services and abilities in our platform.",
        dis: "Affiliate",
        src: require("@/assets/icons/affiliate.svg"),
        option: [
          ["REWARDS", "https://hashtag.space/Affiliates"],
          ["SEE USERS", "https://hashtag.space/SeeUsers"],
          ["INVITE FRIENDS", "https://hashtag.space/SendEmail"],
        ],
      },
      {
        title: "SHIFT",
        tooltip:
          "Upload money to purchase hashtag credits.  Exchange these credits for products and services in our platform, including buying MHC.  Other cryptocurrencies will be available for purchase with hashtag credits in the future.  You can also cross the bridge and exchange your MHC for credits, and cash those credits out to your PayPal account.",
        dis: "The Bridge",
        icon: "mdi-layers-triple-outline",
        link: "https://hashtag.space/pay/",
      },
      {
        title: "TAB",
        tooltip:
          "Connect your various crypto wallets for an easy to monitor interface of your portfolio value. Use Hashtag space as your hang out and watch your crypto fortune grow as well continue to grow for the future.",
        dis: "Portfolio",
        icon: "mdi-diamond-stone",
        link: "https://hashtag.space/portfolio",
      },
    ],
    mainNavItems: [
      { icon: "mdi-inbox", text: "inbox", color: "orange" },
      { icon: "mdi-send", text: "sent" },
      { icon: "mdi-package-down", text: "archive" },
    ],
    isMiniNav: false,

    selectedMainNav: 0,
  }),
  created() {
    if (this.$route.name == "inbox") this.selectedMainNav = 0;
    else if (this.$route.name == "sent") this.selectedMainNav = 1;
    else if (this.$route.name == "archive") this.selectedMainNav = 2;
    else {
      // if (!this.foldersList)
      //   setTimeout(() => {
      //     this.activateFolderAfterFetching();
      //   }, 1500);
      // else {
      const index = Object.keys(this.foldersList).findIndex(
        (ke) => ke == this.$route.params.tagName
      );

      this.selectedMainNav = 3 + index;
    }
    // }
  },
  computed: {
    ...mapGetters(["foldersList"]),
  },
  watch: {
    navDrawer: function (val) {
      this.innerNav = val;
    },
  },
  methods: {
    mouseover() {
      this.isMiniNav = true;
    },
    mouseleave() {
      setTimeout(() => {
        this.isMiniNav = false;
      }, 100);
    },

    openHashtagLink(link) {
      window.open(link, "_self");
    },
    getColor(item) {
      if (item.text == "inbox") {
        if (this.$store.getters.theme === "dark") return "orange";
        return "primary";
      }

      if (this.$store.getters.theme === "dark") return "white";
      return "black";
    },
  },
};
</script>

<style lang="scss" scoped>
.expand-on-hover-card {
  transition: width 0.2s ease-in-out;

  ::v-deep .v-list-item__icon {
    display: none;
  }
}
.list-m-nav {
  transition: all 0.2s ease-in;
}

.expand-on-hover-card:hover {
  width: 225px !important;

  ::v-deep .v-list-item__icon {
    display: inline-flex;
  }
}
.compose-btn {
  background: linear-gradient(#ffc600, #f48f33);
}

.glb-dark {
  .main-btn {
    color: white !important;
  }
}

.glb-white {
  .main-btn {
    color: black !important;
  }
}
</style>
