<template>
  <v-card color="#000000bf" class="mail-view-box">
    <div class="px-1 text-end">
      <v-icon class="white--text" @click="closeBlockDialog"> mdi-close </v-icon>
    </div>

    <v-card-text>
      <v-container>
        <v-row justify="center" class="mb-2">
          <v-col cols="12">
            <v-layout align-center>
              <v-text-field
                v-model.trim="blockName"
                id="name-to-block"
                label="Block User/Domain"
                hide-details
                :dark="$store.getters.theme === 'dark' ? true : false"
              />
              <v-btn
                class="ml-2"
                :loading="onProcess"
                :disabled="onProcess"
                @click="newUserBlock"
              >
                Block
              </v-btn>
            </v-layout>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div class="text-h5 font-weight-medium white--text ma-0">
              Blocked Users
            </div>
          </v-col>
          <v-col cols="6" class="text-right" v-if="viewBlocked.length > 5">
            <v-text-field
              class="pa-0"
              v-model.trim="filter"
              id="filter-blocked-names"
              label="Filter User/Domain"
              @keyup.enter="newUserBlock"
              hide-details
              :dark="$store.getters.theme === 'dark' ? true : false"
            />
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row
          class="height--200 white--text mt-2"
          v-if="viewBlocked.length != 0"
        >
          <v-col
            cols="auto"
            class="pa-1"
            v-for="(name, i) in viewBlocked"
            :key="i"
          >
            <v-chip
              close
              color="teal"
              text-color="white"
              @click:close="removeFromBlocking(name)"
            >
              {{ name }}
            </v-chip>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="mt-2">
            <div class="white--text">No User/Domains are blocked</div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  created() {
    this.blockedNames = this.$store.getters.userInfo.blockedNames;
    this.viewBlocked = this.blockedNames;

    this.blockName = this.$store.getters.tempBlockUserName;
    this.$store.commit("CLEAR_TEMP_NAME");
  },
  data() {
    return {
      blockName: "",
      blockedNames: [],
      viewBlocked: [],
      filter: "",

      onProcess: false,
    };
  },
  watch: {
    filter(val) {
      this.filterView(val);
    },
  },

  methods: {
    filterView(val) {
      this.viewBlocked = this.blockedNames.filter(
        (name) => name.toLowerCase().search(val.toLowerCase()) !== -1
      );
    },
    closeBlockDialog() {
      this.$store.dispatch("HIDE_BLOCKED_USER_POPUP");
    },
    newUserBlock() {
      this.onProcess = true;

      this.$store
        .dispatch("BLOCK_HASHTAG_NAME", {
          hashtagName: this.blockName,
        })
        .then(() => {
          this.onProcess = false;
          this.blockName = "";
          this.$toast.success(`Blocked User/Domain ${this.blockName}`);
        })
        .catch((message) => {
          this.onProcess = false;
          this.$toast.error(message);
        });
    },
    removeFromBlocking(name) {
      this.$store
        .dispatch("UNBLOCK_HASHTAG_NAME", {
          hashtagName: name,
        })
        .then(() => {
          this.filter = "";
          this.$toast.success(`Removed ${name} from blocking`);
        })
        .catch((message) => {
          this.filter = "";
          this.$toast.error(message);
        });
    },
  },
};
</script>

<style scoped>
.dialog {
  color: white;
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
  color: white !important;
  background: grey !important;
}
.button {
  cursor: pointer;
}

.height--200 {
  max-height: 300px;
  position: relative;
  overflow: auto;
}
</style>
